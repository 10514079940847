<template>
  <validation-observer ref="itemCreate" v-slot="{ passes }">
    <v-form @submit.prevent="passes( submit )">
      <v-card flat>
        <v-card-text>
          <v-card flat class="rounded-lg">
            <v-card-title class="d-block">
              <span class="font-weight-bold d-block">Write down and save {{ type }}</span>
            </v-card-title>
            <v-card-text class="pa-8">
              <validation-provider name="first name" rules="required" v-slot="{ errors }">
                <v-text-field type="text"
                              autocomplete="last name"
                              :error-messages="errors"
                              v-model="form.first_name"
                              :hint="`Enter ${type}'s first name`"
                              persistent-placeholder
                              :placeholder="`${type.replace(/^\w/, (c) => c.toUpperCase())}'s first name`">
                  <template v-slot:label>
                    <label class="font-weight-bold">{{ type.replace(/^\w/, (c) => c.toUpperCase()) }}'s first name</label>
                  </template>
                </v-text-field>
              </validation-provider>
              <validation-provider name="last name" rules="required" v-slot="{ errors }">
                <v-text-field type="text"
                              autocomplete="last name"
                              :error-messages="errors"
                              v-model="form.last_name"
                              :hint="`Enter ${type}'s last name`"
                              persistent-placeholder
                              :placeholder="`${type.replace(/^\w/, (c) => c.toUpperCase())}'s last name`">
                  <template v-slot:label>
                    <label class="font-weight-bold">{{ type.replace(/^\w/, (c) => c.toUpperCase()) }}'s last name</label>
                  </template>
                </v-text-field>
              </validation-provider>
              <validation-provider name="e-mail" rules="required|email" v-slot="{ errors }">
                <v-text-field type="email"
                              autocomplete="email"
                              :error-messages="errors"
                              v-model="form.email"
                              :hint="`Enter ${type}'s e-mail`"
                              persistent-placeholder
                              :placeholder="`${type.replace(/^\w/, (c) => c.toUpperCase())}'s e-mail`">
                  <template v-slot:label>
                    <label class="font-weight-bold">{{ type.replace(/^\w/, (c) => c.toUpperCase()) }}'s e-mail</label>
                  </template>
                </v-text-field>
              </validation-provider>
              <validation-provider name="sex" rules="required" v-slot="{ errors }">
                <v-select v-model="form.sex"
                          class="mt-n1"
                          :items="sex"
                          :error-messages="errors">
                  <template v-slot:label>
                    <label class="font-weight-bold">Choose {{ type }}'s sex</label>
                  </template>
                </v-select>
              </validation-provider>
              <validation-provider name="role" rules="required" v-slot="{ errors }">
                <v-select v-model="form.role"
                          class="mt-n1"
                          :items="roles"
                          :error-messages="errors">
                  <template v-slot:label>
                    <label class="font-weight-bold">Choose {{ type }}'s role</label>
                  </template>
                </v-select>
              </validation-provider>
              <label class="font-weight-bold">Generate password automatically?</label>
              <div class="d-flex align-center">
                <span class="d-inline-block mr-2 font-weight-bold">No</span>
                <v-switch v-model="form.passwordGenerate">
                  <template v-slot:label>
                  </template>
                </v-switch>
                <span class="d-inline-block ml-2 font-weight-bold">Yes</span>
              </div>
              <template v-if="!form.passwordGenerate">
                <validation-provider name="password" rules="required|confirmed:confirmation" v-slot="{ errors }">
                  <v-text-field type="password"
                                autocomplete="current-password"
                                :error-messages="errors"
                                v-model="form.password"
                                persistent-placeholder
                                placeholder="Password">
                    <template v-slot:label>
                      <label class="font-weight-bold">Create user's password</label>
                    </template>
                  </v-text-field>
                </validation-provider>
                <validation-provider name="password confirmation" vid="confirmation" v-slot="{ errors }">
                  <v-text-field type="password"
                                autocomplete="password_confirmation"
                                :error-messages="errors"
                                v-model="form.password_confirmation"
                                persistent-placeholder
                                placeholder="Password">
                    <template v-slot:label>
                      <label class="font-weight-bold">Repeat user's password</label>
                    </template>
                  </v-text-field>
                </validation-provider>
              </template>
            </v-card-text>
            <v-card-actions class="pb-8 px-8">
              <v-spacer/>
              <v-btn width="89" height="49" rounded
                     @click="cancel"
                     class="d-flex align-center font-weight-bold"
                     color="secondary" type="button">
                <span class="text-capitalize">Cancel</span>
              </v-btn>
              <v-btn :loading="loading"
                     width="127" height="49" rounded class="d-flex align-center font-weight-bold"
                     color="primary" type="submit">
                <span class="text-capitalize">Save {{ type }}</span>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-card-text>
      </v-card>
    </v-form>
  </validation-observer>
</template>
<script>

export default {
  name: "CreateUserComponent",
  props: {
    endpoint: String,
    type: String,
  },
  data () {
    return {
      loading: false,
      form: {
        passwordGenerate: true,
        sex: null,
        role: 'customer',
        password: null,
        password_confirmation: null
      },
      sex: [
        {
          text: 'Female',
          value: 'female'
        },
        {
          text: 'Male',
          value: 'male'
        },
        {
          text: 'Other',
          value: 'other'
        }
      ],
      roles: [
        {
          text: 'Administrator',
          value: 'admin'
        },
        {
          text: 'Customer',
          value: 'customer'
        }
      ]
    }
  },
  methods: {
    submit () {
      this.loading = true
      this.$http.post(`${process.env.VUE_APP_API_URL}/${this.endpoint}`, this.form)
          .then(() => {
            this.$refs.itemCreate.reset()
            this.form = this.$worker.resetForm(this.form)
            this.loading = false
            this.$root.$emit('admin.user.created')
          })

    },
    cancel () {
      this.$refs.itemCreate.reset()
      this.form = this.$worker.resetForm(this.form)
      this.form.role = 'customer'
      this.$root.$emit('admin.user.cancel')
    }
  }
}
</script>

<style scoped>

</style>