<template>
  <v-row class="d-flex justify-center ma-0 pa-0 fill-height">
    <v-col cols="9" md="12" lg="12" xl="12" class="py-0 px-0 d-flex justify-lg-space-between">
      <v-card elevation="4" :width="maxWidth">
        <v-navigation-drawer class="elevation-none"
                             v-model="drawer"
                             :width="maxWidth"
                             fixed
                             permanent>
          <v-list three-line>
            <v-list-item :to="{name: 'dashboard.admin.show'}">
              <v-list-item-avatar class="my-auto elevation-3">
                <template v-if="user['personal_data']">
                  <v-img :src="user['personal_data'].avatar ? user['personal_data'].avatar : require(`@/assets/avatar-${user['personal_data'].sex}.jpeg`)"
                         max-width="40.36"></v-img>
                </template>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="black--text">
                  <small class="font-weight-bold">Welcome, {{ user.first_name }}</small>
                </v-list-item-title>
                <v-list-item-subtitle class="black--text">
                  <small>{{ user.email }}</small>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-list>
            <template v-for="item in items">
              <template v-if="item['submenu'].length === 0">
                <v-list-item
                    :key="item.text"
                    link
                    :to="{name: item.route}"
                    :active-class="item.route === $route.name ? 'v-list-item--active' : 'transparent'"
                >
                  <v-list-item-icon>
                    <v-icon color="primary">{{ item.icon }}</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title class="primary--text">{{ item.text }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
              <template v-else>
                <v-list-group
                    :key="item.text"
                    link
                    :to="{name: item.route}"
                    :active-class="item.route === $route.name ? 'v-list-item--active' : 'transparent'"
                >
                  <template v-slot:prependIcon>
                    <v-icon color="primary">{{ item.icon }}</v-icon>
                  </template>
                  <template v-slot:appendIcon>
                    <v-icon color="primary">mdi-chevron-down</v-icon>
                  </template>
                  <template v-slot:activator>
                    <v-list-item-content>
                      <v-list-item-title class="primary--text">{{ item.text }}</v-list-item-title>
                    </v-list-item-content>
                  </template>
                  <v-list-item
                      v-for="sub in item['submenu']"
                      :key="sub.text"
                      link
                      :to="{name: sub.route}"
                      class="ml-3"
                      :active-class="sub.route === $route.name ? 'v-list-item--active' : 'transparent'"
                  >
                    <v-list-item-icon>
                      <v-icon color="primary">{{ sub.icon }}</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                      <v-list-item-title class="primary--text">{{ sub.text }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-group>
              </template>
            </template>
          </v-list>
        </v-navigation-drawer>
      </v-card>
      <v-card flat
              tile
              :width="mini ? $vuetify.breakpoint.width - minWidth - 5 : $vuetify.breakpoint.width - maxWidth - 5"
              :style="`padding-left: ${mini ? minWidth - 30 : maxWidth - minWidth * 3.5}px`"
              :min-height="$vuetify.breakpoint.height / 4">
        <v-row class="ma-0 pa-0">
          <v-col cols="12" class="ma-0 pa-0">
            <router-view/>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
    <notification-component/>
  </v-row>
</template>

<script>
import sidebar from "@/static/sidebar.json"

export default {
  name: "Dashboard",
  data () {
    return {
      selected: null,
      mini: false,
      drawer: true
    }
  },
  computed: {
    user() {
      return this.$store.getters.GET_USER
    },
    maxWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
          return 240
        case "lg":
          return 240
        default:
          return 220
      }
    },
    minWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
          return 60
        case "lg":
          return 80
        default:
          return 70
      }
    },
    items () {
      return sidebar['sidebar']
    }
  }
}
</script>

<style scoped>

</style>
