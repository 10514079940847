<template>
<v-snackbar vertical color="secondary" v-model="notification.show" bottom right>
  {{ notification.text }}
  <template v-slot:action="{ attrs }">
    <v-btn
        color="#fff"
        dark
        link
        plain
        :ripple="false"
        v-bind="attrs"
        @click="closeNotification"
    >
      Write report
    </v-btn>
  </template>
</v-snackbar>
</template>

<script>
export default {
  name: "NotificationComponent",
  data () {
    return {
      timer: null
    }
  },
  watch: {
    notification: function () {
      if (this.notification) {
        this.timer = setTimeout(() => {
          this.closeNotification()
        }, 5000)

      }
    }
  },
  methods: {
    closeNotification () {
      this.$store.dispatch('setNotification', { show: false, text: null })
        .then(() => {
          this.$router.push({ name: 'dashboard.report' }).then(() => {
            clearTimeout(this.timer)
          })
        })
    }
  },
  computed: {
    notification () {
      return this.$store.getters.GET_NOTIFICATION
    }
  }
}
</script>

<style scoped>

</style>