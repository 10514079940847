/* eslint-disable no-unused-vars */
import Vue from 'vue'
import VueRouter from 'vue-router'
import middlewarePipeline from "./middlewarePipeline";
import auth from "./middlewares/auth";
import loggedIn from "./middlewares/loggedIn";
import store from '../store'
import Dashboard from "../views/dashboard/Dashboard";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: () => import('../views/Login.vue'),
    meta: {
      middleware: [
        loggedIn
      ]
    }
  },
  {
    path: '/admin',
    component: Dashboard,
    meta: {
      middleware: [
        auth
      ]
    },
    children: [
      {
        path: '',
        component: () => import('../views/dashboard/subviews/Users.vue'),
        name: 'dashboard.admin.users',
        meta: {
          middleware: [
            auth
          ]
        }
      },
      {
        path: '/admin/me',
        component: () => import('../views/dashboard/subviews/Show.vue'),
        name: 'dashboard.admin.show',
        meta: {
          middleware: [
            auth
          ]
        }
      },
      {
        path: '/admin/me/edit',
        component: () => import('../views/dashboard/subviews/Edit.vue'),
        name: 'dashboard.admin.edit',
        meta: {
          middleware: [
            auth
          ]
        }
      },
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

router.beforeEach(async (to, from, next) => {
  store.dispatch('setOverlay', true).catch()

  if (to.name.includes('dashboard')) {
    store.dispatch('setIsDashboard', true).catch()
  } else {
    store.dispatch('setIsDashboard', false).catch()
  }

  if (!to.meta.middleware) {
    return next()
  }

  const middleware = to.meta.middleware
  const context = {
    to,
    from,
    next,
    store
  }
  return middleware[0]({
    ...context,
    next: middlewarePipeline(context, middleware, 1)
  })
})

router.afterEach((to, from) => {
  setTimeout(() => {
    store.dispatch('setOverlay', false).catch()
  }, 800)

})

export default router

