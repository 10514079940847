<template>
  <v-app-bar
      :height="105"
      app
      color="white"
      dark
  >
    <v-spacer/>
    <v-btn @click="logout" color="primary" :width="`${width}%`" height="49" rounded class="d-flex align-center font-weight-bold">
      <small class="mr-2">Log out</small>
      <v-icon small>mb-logout</v-icon>
    </v-btn>
  </v-app-bar>
</template>

<script>

export default {
  name: "AppBarComponent",
  data() {
    return {
      colors: [
        {r: 178, g: 92, b: 30},
        {r: 253, g: 147, b: 52},
        {r: 238, g: 107, b: 31},
        {r: 147, g: 72, b: 38}
      ]
    }
  },
  methods: {
    toDashboard () {
      if (this.$store.getters.GET_IS_DASHBOARD) {
        this.$router.push({ name: 'dashboard' })
      } else {
        this.$router.push({ name: 'login' })
        console.log(true)
      }
    },
    logout () {
      this.$http.post(`${process.env.VUE_APP_API_URL}/auth/logout`)
      .then(() => {
        this.$store.dispatch('setToken', null).then(() => {
          this.$store.dispatch('setRefreshToken', null).then(() => {
            this.$store.dispatch('setUser', {}).then(() => {
              this.$router.push({ name: 'login' })
            })
          })
        })
      })
      .catch(() => {})
    }
  },
  computed: {
    user () {
      return this.$store.getters.GET_USER
    },
    width() {
      switch (this.$vuetify.breakpoint.name) {
        case "md":
          return 12
        case "lg":
          return 8
        case "xl":
          return 6
        default:
          return 10
      }
    }
  }
}
</script>

<style scoped>

</style>
