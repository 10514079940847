import store from '../../store'

export default function loggedIn ({ next }) {
    if (store.getters.GET_TOKEN) {
        if (store.getters.GET_USER.role.includes('admin')) {
            return next({ name: 'dashboard.admin.users' })
        } else {
            return next({ name: 'dashboard' })
        }
    }
    return next()
}
