import { extend, localize, setInteractionMode } from 'vee-validate'
import { email, required, confirmed, alpha_dash, min_value } from 'vee-validate/dist/rules'
import en from "vee-validate/dist/locale/en.json";

setInteractionMode('lazy')

extend('required', required)
extend('email', email)
extend('confirmed', confirmed)
extend('alpha_dash', alpha_dash)
extend('min_value', min_value)
extend('max_files', (value, length) => {
    if (value.length <= length) {
        return true
    }
    return `You try to load more than ${length} files`;
})

localize('en', en)