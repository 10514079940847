var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"itemCreate",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return passes( _vm.submit )}}},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('v-card',{staticClass:"rounded-lg",attrs:{"flat":""}},[_c('v-card-title',{staticClass:"d-block"},[_c('span',{staticClass:"font-weight-bold d-block"},[_vm._v("Write down and save "+_vm._s(_vm.type))])]),_c('v-card-text',{staticClass:"pa-8"},[_c('validation-provider',{attrs:{"name":"first name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"text","autocomplete":"last name","error-messages":errors,"hint":("Enter " + _vm.type + "'s first name"),"persistent-placeholder":"","placeholder":((_vm.type.replace(/^\w/, function (c) { return c.toUpperCase(); })) + "'s first name")},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('label',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.type.replace(/^\w/, function (c) { return c.toUpperCase(); }))+"'s first name")])]},proxy:true}],null,true),model:{value:(_vm.form.first_name),callback:function ($$v) {_vm.$set(_vm.form, "first_name", $$v)},expression:"form.first_name"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"last name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"text","autocomplete":"last name","error-messages":errors,"hint":("Enter " + _vm.type + "'s last name"),"persistent-placeholder":"","placeholder":((_vm.type.replace(/^\w/, function (c) { return c.toUpperCase(); })) + "'s last name")},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('label',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.type.replace(/^\w/, function (c) { return c.toUpperCase(); }))+"'s last name")])]},proxy:true}],null,true),model:{value:(_vm.form.last_name),callback:function ($$v) {_vm.$set(_vm.form, "last_name", $$v)},expression:"form.last_name"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"e-mail","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"email","autocomplete":"email","error-messages":errors,"hint":("Enter " + _vm.type + "'s e-mail"),"persistent-placeholder":"","placeholder":((_vm.type.replace(/^\w/, function (c) { return c.toUpperCase(); })) + "'s e-mail")},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('label',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.type.replace(/^\w/, function (c) { return c.toUpperCase(); }))+"'s e-mail")])]},proxy:true}],null,true),model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"sex","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"mt-n1",attrs:{"items":_vm.sex,"error-messages":errors},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('label',{staticClass:"font-weight-bold"},[_vm._v("Choose "+_vm._s(_vm.type)+"'s sex")])]},proxy:true}],null,true),model:{value:(_vm.form.sex),callback:function ($$v) {_vm.$set(_vm.form, "sex", $$v)},expression:"form.sex"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"role","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"mt-n1",attrs:{"items":_vm.roles,"error-messages":errors},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('label',{staticClass:"font-weight-bold"},[_vm._v("Choose "+_vm._s(_vm.type)+"'s role")])]},proxy:true}],null,true),model:{value:(_vm.form.role),callback:function ($$v) {_vm.$set(_vm.form, "role", $$v)},expression:"form.role"}})]}}],null,true)}),_c('label',{staticClass:"font-weight-bold"},[_vm._v("Generate password automatically?")]),_c('div',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"d-inline-block mr-2 font-weight-bold"},[_vm._v("No")]),_c('v-switch',{scopedSlots:_vm._u([{key:"label",fn:function(){return undefined},proxy:true}],null,true),model:{value:(_vm.form.passwordGenerate),callback:function ($$v) {_vm.$set(_vm.form, "passwordGenerate", $$v)},expression:"form.passwordGenerate"}}),_c('span',{staticClass:"d-inline-block ml-2 font-weight-bold"},[_vm._v("Yes")])],1),(!_vm.form.passwordGenerate)?[_c('validation-provider',{attrs:{"name":"password","rules":"required|confirmed:confirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"password","autocomplete":"current-password","error-messages":errors,"persistent-placeholder":"","placeholder":"Password"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('label',{staticClass:"font-weight-bold"},[_vm._v("Create user's password")])]},proxy:true}],null,true),model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"password confirmation","vid":"confirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"password","autocomplete":"password_confirmation","error-messages":errors,"persistent-placeholder":"","placeholder":"Password"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('label',{staticClass:"font-weight-bold"},[_vm._v("Repeat user's password")])]},proxy:true}],null,true),model:{value:(_vm.form.password_confirmation),callback:function ($$v) {_vm.$set(_vm.form, "password_confirmation", $$v)},expression:"form.password_confirmation"}})]}}],null,true)})]:_vm._e()],2),_c('v-card-actions',{staticClass:"pb-8 px-8"},[_c('v-spacer'),_c('v-btn',{staticClass:"d-flex align-center font-weight-bold",attrs:{"width":"89","height":"49","rounded":"","color":"secondary","type":"button"},on:{"click":_vm.cancel}},[_c('span',{staticClass:"text-capitalize"},[_vm._v("Cancel")])]),_c('v-btn',{staticClass:"d-flex align-center font-weight-bold",attrs:{"loading":_vm.loading,"width":"127","height":"49","rounded":"","color":"primary","type":"submit"}},[_c('span',{staticClass:"text-capitalize"},[_vm._v("Save "+_vm._s(_vm.type))])])],1)],1)],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }