<template>
  <v-snackbar tile
              :width="$vuetify.breakpoint.width / 3"
              color="secondary"
              timeout="-1" top multi-line v-model="show">
    <v-card flat tile color="transparent">
      <v-card-text>
        {{ action.message }}
      </v-card-text>
    </v-card>
    <template v-slot:action>
      <v-spacer></v-spacer>
      <v-btn tile :loading="loading"
             text
             dark
             @click="confirmAction">{{ action.name.replace('-', ' ') }}</v-btn>
      <v-btn tile class="ml-3"
             text
             dark
             @click="closeConfirmation">Cancel</v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: "ConfirmationActionComponent",
  props: {
    loading: Boolean,
    message: null,
    action: Object
  },
  methods: {
    closeConfirmation () {
      this.$store.dispatch('setConfirmation', false)
    },
    confirmAction () {
      this.$root.$emit(this.action.name, this.action.data)
    }
  },
  computed: {
    show () {
      return this.$store.getters.GET_CONFIRMATION
    }
  }
}
</script>

<style scoped>

</style>
