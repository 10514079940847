export default {
    GET_RESPONSE (state) {
        return state.response
    },
    GET_USER (state) {
        return state.user
    },
    GET_TOKEN (state) {
        return state.token
    },
    GET_REFRESH_TOKEN (state) {
        return state.refresh_token
    },
    GET_IS_DASHBOARD (state) {
        return state.isDashboard
    },
    GET_CONFIRMATION (state) {
        return state.confirmation
    },
    GET_ATTACHMENT_PREVIEW (state) {
        return state.attachmentPreview
    },
    GET_OVERLAY (state) {
        return state.overlay
    },
    GET_NOTIFICATION (state) {
        return state.notification
    },
    GET_FIRST_LOGIN (state) {
        return state.first_login
    }
}
