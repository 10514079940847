<template>
  <v-row>
    <v-col cols="12" lg="8" xl="11">
      <v-card class="pa-4" flat>
        <v-card-title class="d-block px-0">
          <div class="d-flex">
            <span class="font-weight-bold d-block">{{ type.toUpperCase() }}</span>
          </div>
        </v-card-title>
        <v-card rounded-lg>
          <v-card-text>
            <v-data-table
                v-model="selected"
                :headers="headers"
                :items="items"
                :loading="loading"
                :options.sync="options"
                :search="search"
                :server-items-length="total"
                item-key="id"
                show-select>
              <template v-slot:top>
                <v-toolbar class="px-0" flat>
                  <v-row class="ma-0 pa-0">
                    <v-col class="px-0" cols="4">
                      <v-text-field v-model="search"
                                    placeholder="Find"
                                    prepend-inner-icon="mb-search"
                                    type="text"></v-text-field>
                    </v-col>
                    <v-col class="d-flex justify-end px-0" cols="8">
                      <v-btn @click="openCreateDialog"
                             color="primary" height="49" rounded width="30">
                        <v-icon color="#fff">mb-plus</v-icon>
                      </v-btn>
                      <template v-if="selected.length > 0">

                        <v-menu
                            bottom
                            left
                            offset-x
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                class="ml-3"
                                height="49" icon
                                v-bind="attrs"
                                width="49"
                                v-on="on"
                            >
                              <v-icon color="primary">mdi-dots-vertical</v-icon>
                            </v-btn>
                          </template>

                          <v-list>
                            <v-list-item
                                v-for="(item, i) in menu"
                                :key="i"
                                :disabled="!item.type"
                                @click="item.method()"
                            >
                              <v-list-item-title class="primary--text">{{ item.title }}</v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </template>
                    </v-col>
                  </v-row>
                </v-toolbar>
              </template>
              <template v-slot:item.data-table-select="{ isSelected, item, select }">
                <template v-if="item.status !== 'done'">
                  <v-checkbox :value="isSelected" @change="select($event)"></v-checkbox>
                </template>
              </template>
              <template v-slot:item.submission="{ item }">
                <template v-if="item['submission']['submission']">
                  <span class="success--text font-weight-bold">Submitted</span>
                </template>
                <template v-else>
                  <span class="error--text font-weight-bold">Waits for submission</span>
                </template>
              </template>
              <template v-slot:item.online="{ item }">
                <template v-if="item.online">
                  <v-icon color="success" small>mdi-circle</v-icon>
                </template>
                <template v-else>
                  <v-icon color="error" small>mdi-circle</v-icon>
                </template>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on"
                           :ripple="false" @click="viewUser(item)" color="transparent" icon small>
                      <v-icon color="primary" small>mdi-eye-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>Show user info</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on"
                           :ripple="false" icon small @click="openConfirmation(item.id)">
                      <v-icon color="primary" small>mb-trash-can</v-icon>
                    </v-btn>
                  </template>
                  <span>Delete user</span>
                </v-tooltip>
                <template v-if="!item['submission']['submission']">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on"
                             :ripple="false" icon small
                             @click="submitMembership(item.id)">
                        <v-icon color="primary" small>mb-plus</v-icon>
                      </v-btn>
                    </template>
                    <span>Submit membership</span>
                  </v-tooltip>
                </template>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-card>
    </v-col>
    <v-dialog v-model="submitDialog"
              :max-width="$vuetify.breakpoint.width * 0.3"
              persistent>
      <v-card>
        <v-card-title>Do you really want to approve membership of this user?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="submitDialog = false">Cancel</v-btn>
          <v-btn color="success" :loading="submittingItem" text @click="submitItem(item.id)">Approve</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog"
              :max-width="$vuetify.breakpoint.width * 0.25"
              persistent>
      <v-card>
        <v-card-title>Do you really want to delete this {{ type }}?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false">Cancel</v-btn>
          <v-btn color="error" text @click="deleteItem(item.id)">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="massDialog"
              :max-width="$vuetify.breakpoint.width * 0.28"
              persistent>
      <v-card>
        <v-card-title>Do you really want to {{ title }} selected {{ type }}?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="massDialog = false">Cancel</v-btn>
          <v-btn color="error" text @click="confirmChosenAction(title)">{{ title }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="createUser"
              :max-width="$vuetify.breakpoint.width * 0.45"
              persistent>
      <create-user-component endpoint="users" type="user"/>
    </v-dialog>
    <v-dialog v-model="previewUser.show"
              :max-width="$vuetify.breakpoint.width * 0.25"
              persistent>
      <show-user-component :user="previewUser.user"/>
    </v-dialog>
  </v-row>
</template>
<script>

export default {
  name: "ItemsListComponent",
  props: {
    endpoint: String,
    type: String,
    routes: Object
  },
  data () {
    return {
      polling: null,
      menu: [
        {
          method: () => this.openMassConfirmation('delete'),
          title: 'Delete selected',
          type: true
        }
      ],
      selected: [],
      item: null,
      dialog: false,
      massDialog: false,
      submitDialog: false,
      items: [],
      loading: true,
      submittingItem: false,
      page: 1,
      options: {},
      total: null,
      search: null,
      form: {},
      title: null,
      createUser: false,
      previewUser: {
        show: false,
        user: {}
      }
    }
  },
  watch: {
    params: {
      handler() {
        this.getItems()
      },
      deep: true,
    }
  },
  created() {
    this.pollData()
  },
  beforeMount() {
    this.getItems()
  },
  mounted() {
    this.$root.$on('admin.user.created', () => {
      this.cancel('createUser')
      this.getItems()
    })
    this.$root.$on('admin.user.cancel', () => {
      this.cancel('createUser')
    })
    this.$root.$on('admin.user.show.cancel', () => {
      this.previewUser.show = false
      this.previewUser.item = {}
    })
  },
  beforeDestroy() {
    clearInterval(this.polling)
  },
  methods: {
    pollData () {
      this.polling = setInterval(() => {
        this.getItems()
      }, 1000 * 60 * 30)
    },
    openConfirmation (id) {
      this.item = id
      this.dialog = true
    },
    submitMembership (id) {
      this.item = id
      this.submitDialog = true
    },
    deleteItem () {
      this.$http.delete(`${process.env.VUE_APP_API_URL}/${this.endpoint}/${this.item}`)
          .then(() => {
            this.dialog = false
            this.getItems()
          })
    },
    submitItem () {
      this.submittingItem = true
      this.$http.get(`${process.env.VUE_APP_API_URL}/submit/${this.item}`)
          .then(() => {
            this.submittingItem = false
            this.submitDialog = false
            this.getItems()
          })
    },
    getItems () {
      this.loading = true
      this.$http.get(`${process.env.VUE_APP_API_URL}${this.url}`)
          .then(response => {
            const {sortBy, sortDesc, page, itemsPerPage} = this.options
            this.items = response.data
            this.total = this.items.length

            let search = this.search ? this.search.trim().toLowerCase() : null;

            if (search) {
              this.items = this.items.filter(item => {
                return Object.values(item)
                    .join(",")
                    .toLowerCase()
                    .includes(search);
              });
            }

            if (sortBy.length === 1 && sortDesc.length === 1) {
              this.items = this.items.sort((a, b) => {
                const sortA = a[sortBy[0]]
                const sortB = b[sortBy[0]]

                if (sortDesc[0]) {
                  if (sortA < sortB) return 1
                  if (sortA > sortB) return -1
                  return 0
                } else {
                  if (sortA < sortB) return -1
                  if (sortA > sortB) return 1
                  return 0
                }
              })
            }

            if (itemsPerPage > 0) {
              this.items = this.items.slice((page - 1) * itemsPerPage, page * itemsPerPage)
            }
            this.loading = false
          })
    },
    viewUser (user) {
      this.previewUser.show = true
      this.previewUser.user = user
    },
    cancel (key) {
      this[key] = false
    },
    openCreateDialog () {
      this.createUser = true
    },
    openMassConfirmation (title) {
      this.title = title
      this.massDialog = true
    },
    confirmChosenAction (action) {
      switch (action) {
        case 'delete':
          this.massDeleting()
          break
        default:
          break
      }
    },
    massDeleting () {
      this.$http.post(`${process.env.VUE_APP_API_URL}/group-deleting/${this.endpoint}`, {
        ids: this.selectedItems
      })
          .then(() => {
            this.getItems()
            this.massDialog = false
          })
          .finally(() => {
            this.selected = []
          })
    },
  },
  computed: {
    headers () {
      return this.$headers[this.type]
    },
    url () {
      return `/${this.endpoint}?page=${this.options.page}`
    },
    params() {
      return {
        ...this.options,
        query: this.search
      };
    },
    selectedItems () {
      const items = []
      this.selected.map( item => {
        items.push(item.id)
      })
      return items
    }
  }
}
</script>

<style scoped>

</style>