<template>
  <canvas data-gradient></canvas>
</template>

<script>
import { GlowParticle } from "../classes/GlowParticle";

export default {
  name: "GradientBackgroundComponent",
  props: {
    colors: null,
    skew: null,
    offsetTop: null,
    width: null,
    height: null
  },
  data () {
    return {
      canvas: null,
      ctx: null,
      pixelRatio: null,
      totalParticles: null,
      particles: null,
      maxRadius: null,
      minRadius: null,
      stageWidth: null,
      stageHeight: null,
    }
  },
  mounted () {
    this.createCanvas()
  },
  methods: {
    createCanvas () {
      this.canvas = document.querySelector('canvas[data-gradient]')
      this.ctx = this.canvas.getContext('2d')
      this.pixelRatio = window.devicePixelRatio > 1 ? 2 : 1

      this.totalParticles = 10
      this.particles = []
      this.maxRadius = 1000
      this.minRadius = 500

      this.resize()
    },
    resize () {
      this.stageWidth = this.WIDTH ? this.WIDTH : document.body.clientWidth
      this.stageHeight = this.HEIGHT ? this.HEIGHT : document.body.clientHeight

      this.canvas.width = this.stageWidth * this.pixelRatio
      this.canvas.height = this.stageHeight * this.pixelRatio

      this.canvas.style.setProperty('--offset-top', this.OFFSET_TOP ? this.OFFSET_TOP : 0)
      this.canvas.style.setProperty('--skew', this.SKEW ? this.SKEW : 0)

      this.ctx.scale(this.pixelRatio, this.pixelRatio)
      this.ctx.globalCompositeOperation = 'saturation'

      this.createParticles()
    },
    animate () {
      window.requestAnimationFrame(this.animate.bind(this))
      this.ctx.clearRect(0, 0, this.stageWidth, this.stageHeight)

      for (let i = 0; i < this.totalParticles; i++) {
        const item = this.particles[i]
        item.animate(this.ctx, this.stageWidth, this.stageHeight)
      }
    },
    createParticles () {
      let curColor = 0
      this.particles = []

      for (let i = 0; i < this.totalParticles; i++) {
        const item = new GlowParticle(
            Math.random() * this.stageWidth,
            Math.random() * this.stageHeight,
            Math.random() * (this.maxRadius - this.minRadius) + this.minRadius,
            this.COLORS[curColor]
        )

        if (++curColor >= this.COLORS.length) {
          curColor = 0
        }

        this.particles[i] = item
      }
      this.animate()
    }
  },
  computed: {
    COLORS () {
      return this.colors
    },
    OFFSET_TOP () {
      return this.offsetTop
    },
    SKEW () {
      return this.skew
    },
    WIDTH () {
      return this.width
    },
    HEIGHT () {
      return this.height
    }
  }
}
</script>

<style scoped lang="scss">
canvas {
  &[data-gradient] {
    position: absolute;
    top: calc(var(--offset-top) * 1vh);
    left: 0;
    z-index: 0;
    max-height: 100vh;
    max-width: 100vw;
    transform: skewY(calc(var(--skew) * 1deg));
  }
}
</style>
