import Vue from 'vue'

Vue.filter('remove_sign', (value, sign, replacement) => {
    return value.replace(sign, replacement)
})

Vue.filter('truncate', (value, length) => {
    if (value.length <= length) {
        return value
    }
    return `${value.slice(0, length)}...`
})