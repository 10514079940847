export default {
    response: {
        status: false
    },
    user: {},
    token: null,
    refresh_token: null,
    isDashboard: false,
    confirmation: false,
    attachmentPreview: {},
    overlay: false,
    notification: {},
    first_login: false
}
