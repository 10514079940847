import Vue from "vue"
import AppBarComponent from "../components/AppBarComponent";
import {ValidationObserver, ValidationProvider} from "vee-validate";
import GradientBackgroundComponent from "../components/GradientBackgroundComponent";
import { StripeElementCard } from "@vue-stripe/vue-stripe"
import ConfirmationActionComponent from "../components/ConfirmationActionComponent";
import AlertComponent from "../components/AlertComponent";
import BackButtonComponent from "../components/BackButtonComponent";
import NotificationComponent from "../components/NotificationComponent";
import CreateUserComponent from "../components/admin/CreateUserComponent";
import UsersListComponent from "../components/admin/UsersListComponent";
import ShowUserComponent from "../components/admin/ShowUserComponent";

Vue.component('app-bar-component', AppBarComponent)
Vue.component('validation-observer', ValidationObserver)
Vue.component('validation-provider', ValidationProvider)
Vue.component('gradient-background-component', GradientBackgroundComponent)
Vue.component('stripe-element-card', StripeElementCard)
Vue.component('confirmation-action-component', ConfirmationActionComponent)
Vue.component('alert-component', AlertComponent)
Vue.component('back-button-component', BackButtonComponent)
Vue.component('users-data-table-component', UsersListComponent)
Vue.component('create-user-component', CreateUserComponent)
Vue.component('show-user-component', ShowUserComponent)
Vue.component('notification-component', NotificationComponent)