<template>
  <v-card flat class="rounded-lg">
    <v-card-title>User's info</v-card-title>
    <v-card-text class="pa-6">
      <div>
        <label class="font-weight-bold">First name</label>
        <span class="d-block">
                      {{ user.first_name }}
                    </span>
      </div>
      <div>
        <label class="font-weight-bold">Last name</label>
        <span class="d-block">
                      {{ user.last_name }}
                    </span>
      </div>
      <div>
        <label class="font-weight-bold">E-mail</label>
        <span class="d-block">
                      {{ user.email }}
                    </span>
      </div>
      <div>
        <label class="font-weight-bold">Sex</label>
        <span class="d-block">
                      {{ user.personal_data.sex }}
                    </span>
      </div>
      <div>
        <label class="font-weight-bold">Role</label>
        <span class="d-block">
                      {{ user.role }}
                    </span>
      </div>
      <div>
        <label class="font-weight-bold">Last session duration</label>
        <span class="d-block">
                      {{ parseSession(user.session_duration) }}
                    </span>
      </div>
    </v-card-text>
    <v-card-actions class="pb-4 px-4">
      <v-spacer/>
      <v-btn width="89" height="49" rounded
             @click="cancel"
             class="d-flex align-center font-weight-bold"
             color="secondary" type="button">
        <span class="text-capitalize">Close</span>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>

export default {
  name: "ShowItemComponent",
  props: {
    user: Object
  },
  methods: {
    cancel () {
      this.form = this.$worker.resetForm(this.form)
      this.$root.$emit('admin.user.show.cancel')
    },
    parseSession (session) {
      let time = {
        hours: null,
        minutes: null,
        seconds: null
      }
      if (session.hours > 0) {
        if (session.hours > 1) {
          time.hours = `${session.hours} hours `;
        } else {
          time.hours = `${session.hours} hour `;
        }
      }
      if (session.minutes > 0) {
        if (session.minutes > 1) {
          time.minutes = `${session.minutes} minutes `;
        } else {
          time.minutes = `${session.minutes} minute `;
        }
      }

      if (session.seconds > 0) {
        if (session.seconds > 1) {
          time.seconds = `${session.seconds} seconds`;
        } else {
          time.seconds = `${session.seconds} second`;
        }
      }

      return `${time.hours ? time.hours : ''}${time.minutes ? time.minutes : ''}${time.seconds ? time.seconds : ''}`
    }
  }
}
</script>

<style scoped>

</style>