import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: "#FD9334",
                secondary: "#FFBC45",
                accent: "#3052A7",
                warning: "#F3C623",
                error: "#731E0B",
                success: "#169099"
            },
        },
    }
});
