export default {
    setResponse({ commit }, data) {
        commit('SET_RESPONSE', data)
    },
    setUser({ commit }, data) {
        commit('SET_USER', data)
    },
    setToken({ commit }, data) {
        commit('SET_TOKEN', data)
    },
    setRefreshToken({ commit }, data) {
        commit('SET_REFRESH_TOKEN', data)
    },
    setIsDashboard({ commit }, data) {
        commit('SET_IS_DASHBOARD', data)
    },
    setConfirmation ({ commit }, data) {
        commit('SET_CONFIRMATION', data)
    },
    setAttachmentPreview ({ commit }, data) {
        commit('SET_ATTACHMENT_PREVIEW', data)
    },
    setOverlay ({ commit }, data) {
        commit('SET_OVERLAY', data)
    },
    setNotification ({ commit }, data) {
        commit('SET_NOTIFICATION', data)
    },
    setFirstLogin ({ commit }, data) {
        commit('SET_FIRST_LOGIN', data)
    }

}
