<template>
  <v-btn @click="goToPreviousRoute"
         :ripple="false"
         plain color="primary" class="d-flex justify-start pl-0 mb-2">
    <v-icon>mdi-arrow-left</v-icon>
    <span class="ml-2 text-capitalize">Back</span>
  </v-btn>
</template>

<script>
export default {
  name: "BackButtonComponent",
  methods: {
    goToPreviousRoute () {
      this.$router.back()
    }
  }
}
</script>

<style scoped>

</style>