import Vue from 'vue'
import Http from "../../plugins/http.plugin";
import store from "../../store"
Vue.use(Http)

export default function auth ({ next }) {
    Vue.prototype.$http.get(`${process.env.VUE_APP_API_URL}/auth/me`)
        .then((response) => {
            if (response !== undefined) {
                store.dispatch('setUser', response.data.me).then(() => {
                    if (store.getters.GET_USER.first) {
                        store.dispatch('setFirstLogin', true).catch()
                    }
                })
                return next()
            }
            return next({ name: 'login' })
        })
        .catch(() => {
            return next({ name: 'login' })
        })
}
