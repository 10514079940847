const WorkerService = {
    /* eslint-disable no-prototype-builtins */
    install(Vue) {
        Vue.prototype.$worker = {
            removeProperties: (object, properties) => {
                if (Array.isArray(properties)) {
                    properties.forEach((property) => {
                        // eslint-disable-next-line no-prototype-builtins
                        object.hasOwnProperty(property) ?
                            delete object[property] : null;
                    })
                } else {
                    delete object[properties];
                }

                return object;
            },
            addProperties: (object, properties) => {
                if (Array.isArray(properties)) {
                    properties.forEach((property) => {
                        // eslint-disable-next-line no-prototype-builtins
                        object.hasOwnProperty(property) ? null : object[property] = null;
                    })
                } else {
                    // eslint-disable-next-line no-prototype-builtins
                    object.hasOwnProperty(properties) ? null : object[properties] = null;
                }

                return object;
            },
            resetForm: (object) => {
                for (let property in object) {
                    if (object.hasOwnProperty(property)) {
                        if (Array.isArray(object[property])) {
                            object[property] = []
                        } else {
                            object[property] = null
                        }
                    }
                }
                return object
            },
            setForm: (form) => {
                const fd = new FormData()
                for (const key in form) {
                    // eslint-disable-next-line no-prototype-builtins
                    if (key && form.hasOwnProperty(key)) {
                        if (key.includes('file')) {
                            form[key].forEach(file => {
                                fd.append('file[]', file)
                            })
                        } else {
                            fd.append(key, form[key])
                        }
                    }
                }
                return fd
            }
        }
    }
}

export default (WorkerService);
