<template>
  <v-app>
    <template v-if="show">
      <app-bar-component/>
    </template>
    <v-main style="position: relative">
      <template v-if="!show">
        <gradient-background-component :colors="colors" :skew="-22" :offset-top="-50"/>
      </template>
      <v-container fill-height class="pa-0" fluid>
        <transition name="fade">
          <router-view/>
        </transition>
      </v-container>
    </v-main>
    <alert-component/>
    <v-overlay v-model="overlay" color="white" absolute opacity="1" z-index="99">
      <v-progress-circular
          indeterminate
          color="primary"
          size="64"
      ></v-progress-circular>
    </v-overlay>
  </v-app>
</template>

<script>

export default {
  name: 'App',
  data() {
    return {
      colors: [
        {r: 178, g: 92, b: 30},
        {r: 253, g: 147, b: 52},
        {r: 238, g: 107, b: 31},
        {r: 147, g: 72, b: 38}
      ]
    }
  },
  mounted () {
  },
  methods: {

  },
  computed: {
    show() {
      return this.$store.getters.GET_IS_DASHBOARD
    },
    overlay () {
      return this.$store.getters.GET_OVERLAY
    }
  }
};
</script>
